import {
  funcScrollControl,
  funcLoad,
  comMain,
} from './index';

const funcEntry = (() => {


  funcScrollControl.noScroll();

  const pageTop = document.getElementById('pageTop');

  if (pageTop) {
    document.addEventListener("DOMContentLoaded", () => {
      funcLoad()
    });
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      comMain()
      funcLoad()
    });
  }


})();
export default funcEntry;