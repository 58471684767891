const comMain = () => {
  const comSecMain = (document.getElementById("jsComSecMain") != null);
  if (comSecMain) {



    let comSecMain = document.getElementById('jsComSecMain');
    let imgBox = comSecMain.querySelector('.js-img-box');


    setTimeout(() => {
      imgBox.classList.add('act')
    }, 100);



  }
}
export default comMain;