const topConcept = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    let sW = window.innerWidth;

    let secConcept = document.getElementById('jsSecConcept');
    let bgWrap = secConcept.querySelector('.js-bg-wrap');
    let borderBox = secConcept.querySelector('.js-border-box');
    let logoBox = secConcept.querySelector('.js-logo-box');
    let copyBox = secConcept.querySelector('.js-copy-box');

    let svg = bgWrap.querySelector('svg');
    let svgPath = svg.querySelector('path');
    let snapSvg = Snap(svg);
    let snapSvgPath = snapSvg.select('path');
    // console.log(snapSvgPath);

    let dBefore
    let dAfter

    if (sW > 750) {
      dBefore = 'M410.0001,352.459326h0c0-189.1372342-.0000224-352.459326-.00005-352.459326-.0000276,0-.00005,163.3220918-.00005,352.459326v1041.540674h.0001V352.459326Z'
      dAfter = 'M819.9995129,352.459326h.0004871C820,163.3220918,636.4365945,0,410,0,183.5632838,0,0,163.3220918,0,352.459326v1041.540674h819.9995129V352.459326Z'
    } else {
      dBefore = 'M163.500111,0c-.0000276,0-.00005,64.9888916-.00005,140.2501221v414.4486084h.0001V140.2501221h0c0-75.2612305-.0000224-140.2501221-.00005-140.2501221Z'
      dAfter = 'M163.500061,0C73.2015175,0,0,64.9888916,0,140.2501221v414.4486084h327V140.2501221h.0001221C327.0001221,64.9888916,253.7986046,0,163.500061,0Z'
    }


    snapSvgPath.animate({
      'd': dBefore,
    }, 1);


    let tl = gsap.timeline({
      scrollTrigger: {
        invalidateOnRefresh: true,
        trigger: bgWrap,
        start: "0% 20%",
        onEnter: () => {
          snapSvgPath.animate({
            'd': dAfter,
          }, 1);
          setTimeout(() => {
            borderBox.classList.add('act')
          }, 1200);
          setTimeout(() => {
            logoBox.classList.add('act')
          }, 1200);
          setTimeout(() => {
            copyBox.classList.add('act')
          }, 2000);
        },
        // markers: true,
      },
    })


    let target = document.querySelector('body');
    let observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        tl.scrollTrigger.refresh()
      });
    });
    observer.observe(target);


  }
})()
export default topConcept;