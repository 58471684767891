const locationLifeinfo = (() => {
  const pageLocation = (document.getElementById("pageLocation") != null);
  if (pageLocation) {



    let secLi = document.querySelector('#jsSecLi');
    let liWrap = secLi.querySelector('.js-li-wrap');
    let btnBoxs = liWrap.querySelectorAll('.js-btn-box');
    let listBoxs = liWrap.querySelectorAll('.js-list-box');

    btnBoxs[0].classList.add('act');
    listBoxs[0].classList.add('act');

    btnBoxs.forEach((element, index) => {

      element.addEventListener('click', () => {

        for (let i = 0; i < btnBoxs.length; i++) {
          btnBoxs[i].classList.remove('act');
          listBoxs[i].classList.remove('act');
        }

        btnBoxs[index].classList.add('act');
        listBoxs[index].classList.add('act');

      });

    });




  }
})()
export default locationLifeinfo;