const typeTab = (() => {
  const pageType = (document.getElementById("pageType") != null);
  if (pageType) {



    let secType = document.querySelector('#jsSecType');
    let tabBoxs = secType.querySelectorAll('.js-tab-box');
    let typeWraps = secType.querySelectorAll('.js-type-wrap');


    if (tabBoxs.length > 0 && typeWraps.length > 0) {


      tabBoxs.forEach((element, index) => {
        // console.log(index)
        tabBoxs[index].classList.add('none');
        typeWraps[index].classList.add('none');
        tabBoxs[0].classList.remove('none');
        typeWraps[0].classList.remove('none');
      });


      tabBoxs.forEach((element, index) => {

        element.addEventListener('click', () => {
          // console.log(index)
          for (let i = 0; i < tabBoxs.length; i++) {
            tabBoxs[i].classList.add('none');
            typeWraps[i].classList.add('none');
          }

          tabBoxs[index].classList.remove('none');
          typeWraps[index].classList.remove('none');
        });

      });

    }





  }
})()
export default typeTab;